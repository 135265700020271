import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorMessage: {
      color: "red",
      marginTop: theme.spacing(8),
    },
  })
);

interface ConfirmDialogProps {
  onClose: (options: { reload: boolean }) => void;
  open: boolean;
  action: () => Promise<void>;
  dialogContentText: string;
}

export default function ConfirmDialog(props: ConfirmDialogProps) {
  const classes = useStyles();
  const [confirmDisabled, setConfirmDisabled] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { onClose, open, action, dialogContentText } = props;

  function handleClose() {
    onClose({ reload: false });
  }

  async function performAction() {
    setConfirmDisabled(true);
    try {
      await action();
      onClose({ reload: true });
    } catch (err) {
      console.error(err);
      setErrorMessage(err.response.data.message);
    } finally {
      setConfirmDisabled(false);
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{dialogContentText}</DialogContentText>
        {errorMessage && <DialogContentText className={classes.errorMessage}>{errorMessage}</DialogContentText>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button disabled={confirmDisabled} onClick={performAction} color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
