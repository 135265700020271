import { createMuiTheme, Theme } from "@material-ui/core/styles";
import { DarkBlue, LinkBlueSky, White } from "@amplyfi/ui-components/theme/colors";

export const theme: Theme = createMuiTheme({
  palette: {
    primary: {
      main: DarkBlue,
    },
    secondary: {
      main: LinkBlueSky,
      contrastText: "#FFF",
    },
    background: {
      default: White,
    },
  },
  typography: {
    fontFamily: "Gordita, sans-serif",
  },
});

export default theme;
