import React, { Component, FunctionComponent, useEffect } from "react";
import { Route, RouteProps } from "react-router-dom";
import { useAuth0 } from "./AuthWrapper";

interface PrivateRouteProps {
  component?: React.FunctionComponent;
  path: string;
}

const PrivateRoute: FunctionComponent<PrivateRouteProps & RouteProps> = (props) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();
  const { path } = props;

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: path },
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  const render = (props: any) => (isAuthenticated === true && Component ? <Component {...props} /> : undefined);

  return <Route path={path} render={render} {...props} />;
};

export default PrivateRoute;
